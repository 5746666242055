<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card class="mx-auto" style="max-width: 500px" color="success" dark>
          <v-card-title>
            <v-icon large left> mdi-check-bold </v-icon>
            <span class="text-h6 font-weight-light">
              Vos données ont bien été sauvegardées
            </span>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card class="mx-auto" style="max-width: 500px">
          <v-card-title>
            <span class="text-h6 font-weight-light">
              Vous pouvez revenir sur :
              <br/>
              <br/>
              <b>www.familledumontdechassart.be</b> 
              <br/>
              à tout moment pour mettre à jour
              vos données
            </span>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
