<template>
  <email />
</template>

<script>
  import Email from '../components/Email'

  export default {
    name: 'Home',

    components: {
      Email,
    },
  }
</script>
