const env = process.env.NODE_ENV;

let envApiUrl = '';

if (env === 'production') {
  // envApiUrl = "https://guarded-temple-12210.herokuapp.com";
  envApiUrl = "https://dumont-backend.vercel.app";

} else {
  envApiUrl = "http://127.0.0.1:8000";
}

export const apiUrl = envApiUrl;
