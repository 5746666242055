<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card v-if="!isEmailSend" class="mx-auto" style="max-width: 500px">
          <v-card-title class="justify-center">
            <h3 class="text-center">
              Association de famille <br />
              Dumont de Chassart
            </h3>
          </v-card-title>

          <v-card-text class="mt-10">
            <v-alert type="info">
              Dans le but de <strong>mettre à jour </strong>nos données, nous
              vous invitons à remplir votre email. <br />
              Vous receverez un <strong>email</strong> avec un lien pour mettre
              a jour vos données.
              <br />

              Nous utilisons cet email pour vous <strong>communiquer</strong> les nouvelles de la famille.
            </v-alert>


            <v-text-field
              v-model="email"
              outlined
              label="Entrer votre email"
              type="email"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn
              block
              depressed
              large
              :disabled="!isValidEmail"
              :loading="pending"
              color="primary"
              @click="saveEmail"
            >
              Confirmer
            </v-btn>
          </v-card-actions>
        </v-card>

        <v-card
          v-else
          class="mx-auto"
          style="max-width: 500px"
          color="success"
          dark
        >
          <v-card-title>
            <v-icon large left> mdi-email </v-icon>
            <span class="text-h6 font-weight-light">
              Vous allez recevoir un lien par <strong>email</strong> pour
              completer vos informations.
            </span>
          </v-card-title>

          <v-card-text>
            Il est possible que l'email arrive dans vos spams.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { apiUrl } from "@/env.js";
const axios = require("axios").default;

var emailRE =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default {
  data() {
    return {
      email: "",
      isEmailSend: false,
      pending: false,
      api: apiUrl,
    };
  },
  computed: {
    isValidEmail() {
      return emailRE.test(this.email);
    },
  },
  methods: {
    saveEmail() {
      this.pending = true;
      axios
        .post(apiUrl + "/users", this.email)
        .then(() => {
          this.isEmailSend = true;
          this.pending = false;
        })
        .catch((error) => {
          console.log(error);
          this.isEmailSend = true;
          this.pending = false;
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
