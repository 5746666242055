<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card class="mx-auto" style="max-width: 500px" color="error" dark>
          <v-card-title>
            <v-icon large left> mdi-alert-circle </v-icon>
            <span class="text-h6 font-weight-light">
              Une erreur c'est produite
            </span>
          </v-card-title>

          <v-card-text>
            Vous pouvez en informer le comité.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
