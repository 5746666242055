<template>
  <v-container>
    <div v-if="user">
      <v-row justify="center">
        <v-col cols="12" sm="10" md="8" lg="6">
          <v-card ref="form">
            <v-card-title>Mes informations</v-card-title>
            <v-card-text>
              <v-text-field
                ref="prenom"
                v-model="user.prenom"
                label="Prénom"
                placeholder="Auguste"
              ></v-text-field>

              <v-text-field
                ref="nom"
                v-model="user.nom"
                label="Nom"
                placeholder="Dumont de Chassart"
              ></v-text-field>

              <v-text-field
                ref="rue"
                v-model="user.rue"
                label="Rue"
                placeholder="Rue de Chassart"
              ></v-text-field>

              <v-text-field
                ref="numero"
                v-model="user.numero"
                label="Numero de rue"
                placeholder="123"
                type="number"
              ></v-text-field>

              <v-text-field
                ref="boite"
                v-model="user.boite"
                label="Boite"
                placeholder="2c"
              ></v-text-field>

              <v-text-field
                ref="code_postal"
                v-model="user.code_postal"
                label="Code Postal"
                placeholder="6210"
              ></v-text-field>

              <v-text-field
                ref="commune"
                v-model="user.commune"
                label="Commune"
                placeholder="Villers-Perwin"
              ></v-text-field>

              <v-autocomplete
                ref="pays"
                v-model="user.pays"
                :items="countries"
                label="Pays"
                placeholder="Choisir..."
              ></v-autocomplete>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="updateUser" :loading="pending">
                Sauvegarder
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <div v-else>
      <v-row justify="center">
        <v-col>
          <p class="text-center">Chargement des données</p>

          <p class="text-center">
            <v-progress-circular
              :size="20"
              :width="2"
              color="green"
              indeterminate
            ></v-progress-circular>
          </p>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { apiUrl } from "@/env.js";
const axios = require("axios").default;

export default {
  data() {
    return {
      countries: [
        "Belgium",
        "Afghanistan",
        "Albania",
        "Algeria",
        "Andorra",
        "Angola",
        "Anguilla",
        "Antigua &amp; Barbuda",
        "Argentina",
        "Armenia",
        "Aruba",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belize",
        "Benin",
        "Bermuda",
        "Bhutan",
        "Bolivia",
        "Bosnia &amp; Herzegovina",
        "Botswana",
        "Brazil",
        "British Virgin Islands",
        "Brunei",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Cambodia",
        "Cameroon",
        "Cape Verde",
        "Cayman Islands",
        "Chad",
        "Chile",
        "China",
        "Colombia",
        "Congo",
        "Cook Islands",
        "Costa Rica",
        "Cote D Ivoire",
        "Croatia",
        "Cruise Ship",
        "Cuba",
        "Cyprus",
        "Czech Republic",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic",
        "Ecuador",
        "Egypt",
        "El Salvador",
        "Equatorial Guinea",
        "Estonia",
        "Ethiopia",
        "Falkland Islands",
        "Faroe Islands",
        "Fiji",
        "Finland",
        "France",
        "French Polynesia",
        "French West Indies",
        "Gabon",
        "Gambia",
        "Georgia",
        "Germany",
        "Ghana",
        "Gibraltar",
        "Greece",
        "Greenland",
        "Grenada",
        "Guam",
        "Guatemala",
        "Guernsey",
        "Guinea",
        "Guinea Bissau",
        "Guyana",
        "Haiti",
        "Honduras",
        "Hong Kong",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran",
        "Iraq",
        "Ireland",
        "Isle of Man",
        "Israel",
        "Italy",
        "Jamaica",
        "Japan",
        "Jersey",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kuwait",
        "Kyrgyz Republic",
        "Laos",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Macau",
        "Macedonia",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Mauritania",
        "Mauritius",
        "Mexico",
        "Moldova",
        "Monaco",
        "Mongolia",
        "Montenegro",
        "Montserrat",
        "Morocco",
        "Mozambique",
        "Namibia",
        "Nepal",
        "Netherlands",
        "Netherlands Antilles",
        "New Caledonia",
        "New Zealand",
        "Nicaragua",
        "Niger",
        "Nigeria",
        "Norway",
        "Oman",
        "Pakistan",
        "Palestine",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines",
        "Poland",
        "Portugal",
        "Puerto Rico",
        "Qatar",
        "Reunion",
        "Romania",
        "Russia",
        "Rwanda",
        "Saint Pierre &amp; Miquelon",
        "Samoa",
        "San Marino",
        "Satellite",
        "Saudi Arabia",
        "Senegal",
        "Serbia",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Slovakia",
        "Slovenia",
        "South Africa",
        "South Korea",
        "Spain",
        "Sri Lanka",
        "St Kitts &amp; Nevis",
        "St Lucia",
        "St Vincent",
        "St. Lucia",
        "Sudan",
        "Suriname",
        "Swaziland",
        "Sweden",
        "Switzerland",
        "Syria",
        "Taiwan",
        "Tajikistan",
        "Tanzania",
        "Thailand",
        `Timor L'Este`,
        "Togo",
        "Tonga",
        "Trinidad &amp; Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Turks &amp; Caicos",
        "Uganda",
        "Ukraine",
        "United Arab Emirates",
        "United Kingdom",
        "United States",
        "Uruguay",
        "Uzbekistan",
        "Venezuela",
        "Vietnam",
        "Virgin Islands (US)",
        "Yemen",
        "Zambia",
        "Zimbabwe",
      ],
      user: null,
      pending: false,
    };
  },
  computed: {},
  methods: {
    updateUser() {
      this.pending = true;
      axios
        .put(apiUrl + "/users/" + this.user.id, this.user)
        .then(() => {
          this.pending = false;
          this.$router.push({name: "Success"})
        })
        .catch((error) => {
          console.log(error);
          this.pending = false;
          this.$router.push({name: "Failure"})
        });
    },
  },
  beforeCreate() {
    axios
      .get(apiUrl + "/users/" + this.$route.params.id)
      .then((response) => {
        if (response.data.status === "success") {
          this.user = response.data.data;
        }
      })
      .catch((error) => {
        this.$router.push("/");
        console.log(error);
      });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
